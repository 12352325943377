import * as React from "react";

export default function FileUpload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.053"
      height="30.003"
      viewBox="0 0 37.053 30.003"
    >
      <g id="upload-cloud" transform="translate(-0.239 -2.247)">
        <path
          id="Path_20785"
          data-name="Path 20785"
          d="M20.889,18.445,14.445,12,8,18.445"
          transform="translate(4.282 5.499)"
          fill="none"
          stroke="#111"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_2820"
          data-name="Line 2820"
          y2="14"
          transform="translate(18.718 17.5)"
          fill="none"
          stroke="#111"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_20786"
          data-name="Path 20786"
          d="M32.244,27.795a8.056,8.056,0,0,0-3.851-15.129h-2.03A12.889,12.889,0,1,0,4.226,24.427"
          transform="translate(0)"
          fill="none"
          stroke="#111"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_20787"
          data-name="Path 20787"
          d="M20.889,18.445,14.445,12,8,18.445"
          transform="translate(4.282 5.499)"
          fill="none"
          stroke="#111"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
