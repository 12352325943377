import moment from "moment";

export const dateAndTime = (value: string) => {
  return dateByFormat(value, "YYYY-MM-DD HH:mm:ss");
};

export const dateByFormat = (value: string | Date, formatter: string) => {
  if (!value) {
    return "";
  }
  const m = moment.utc(value);

  if (!m.isValid()) {
    return value;
  }
  return m.format(formatter);
};

export const currentDay = () => {
  return moment().format("dddd");
};

export const currentTime = () => {
  return moment().format("hh:mm");
};

export const isTimeNowBetween = (time1: string, time2: string) => {
  if (!time1 || !time2) {
    return false;
  }
  const format = "hh:mm:ss";
  const currentTime = moment();
  const momentTime1 = moment(time1, format);
  const momentTime2 = moment(time2, format);
  return currentTime.isBetween(momentTime1, momentTime2);
};

export const startOfToday = () => {
  return moment().startOf("day");
};

// Will return the start of the day, weeks ago
export const getWeeksBefore = (weeks: number) => {
  return moment().subtract(weeks, "w").startOf("day").toDate();
};

// Will return the start of the day, months ago
export const getMonthsBefore = (months: number) => {
  return moment().subtract(months, "M").startOf("day").toDate();
};
// Will return the start of the day, years ago
export const getYearsBefore = (years: number) => {
  return moment().subtract(years, "y").startOf("day").toDate();
};
