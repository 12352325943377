import * as React from "react";
import TextField from "@mui/material/TextField";
//import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import DatePicker from "@mui/lab/DatePicker";
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
type Props ={
  label: string;
  value: any;
  onChange: any;
  views? : any;
  size?: "small" | "medium"
}
export default function DatePickerComponent(props: Props) {
  const {label, value, onChange, views, size} = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        views={views}
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} size={size} fullWidth  sx={{backgroundColor:'#ffffff'}}/>}
      />
    </LocalizationProvider>
  );
}
