import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function QrModal({
  open,
  handleClose,
  status,
  qrCode,
  matches,
  errorMessage
}) {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <Card
          sx={{
            width: matches ? "414px" : "313px",
            height: "390px",
            borderRadius: "14px 14px 0px 0px",
            marginTop: "10%",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10%",
              }}
            >
              {status === "waiting" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: matches ? "24px" : "15px",
                    }}
                  >
                     <Box sx={{ width: "100%", marginY: "1rem" }}>
                      <Box sx={{ width: "100%", marginBottom: "1rem", fontWeight:"bold" }}>Please scan this QR</Box>
                    <LinearProgress color="secondary" />
                  </Box>
                  <img src={qrCode} alt="QR"/>
                  </Box>
                </>
              )}
              {status === "error" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: matches ? "20px" : "15px",
                        fontWeight: "bold",
                      }}
                    >
                      <Box>Transaction failed</Box>
                      {/*<Box>ادایئگی ناکام</Box>*/}
                    </Box>
                    {errorMessage && (
                      <Box
                        sx={{
                          marginY: "0.5rem",
                          padding: "0.5rem",
                          color: "#FF1D1D",
                          backgroundColor: "#FFE4E4",
                        }}
                      >
                        {errorMessage}
                      </Box>
                    )}
                    <Box sx={{ color: "#898989" }}>
                      Your transaction is failed
                    </Box>
                  </Box>
                </>
              )}
              {status === "success" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "1rem",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: matches ? "20px" : "15px",
                        fontWeight: "bold",
                      }}
                    >
                      <Box>Transaction successful</Box>
                      {/*<Box>ادائیگی کامیاب</Box>*/}
                    </Box>
                    <Box sx={{ color: "#898989" }}>
                      Your transaction is processed successfully
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Backdrop>
    </div>
  );
}
