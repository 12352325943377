import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
//import { history } from "&store/store";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { settlementActions } from "./settlement.slice";
import { formatter } from "&config/currencyFormatter";

type ReduxProps = ConnectedProps<typeof connector>;

const SettlementComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getSettlements } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "ID",
      width: 150,
      renderCell: ({ row }) => (
        <Box sx={{ color: "#6631F7" }}>{Math.round(row.transactionId)}</Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date",
      width: 200,
      renderCell: ({ row }) =>
        moment(row.transactionDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "channel",
      headerName: "Channel",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "settledCurrency",
      headerName: "Currency",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "totalRevenue",
      headerName: "Total Revenue",
      renderCell: ({ row }) => formatter("PK", "PKR").format(row.totalRevenue),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "netSettledAmount",
      headerName: "Net Settled Amount",
      renderCell: ({ row }) =>
        formatter("US", "USD").format(row.netSettledAmount),
      width: 150,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "country",
      headerName: "Country",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "exchangeRate",
      headerName: "Ex. Rate",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "remittanceCharges",
      headerName: "Remittance Charges",
      width: 150,
      renderCell: ({ row }) =>
        formatter("PK", "PKR").format(row.remittanceCharges),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "remittanceChargesPercentage",
      headerName: "Remittance Tax",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      const resp = await getSettlements();
      console.log("setlement resp :", resp);
    })();
  }, [getSettlements]);

  return (
    <>
      <PageHeader
        title="Settlements"
        onAdd={() => history.push("/settlement/form/new")}
        addText="Create Settlement"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.settlement,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSettlements: settlementActions.getSettlements,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const SettlementComponentRedux = connector(SettlementComponent);

export { SettlementComponentRedux as SettlementComponent };
