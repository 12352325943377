import React from "react";
import Box from "@mui/material/Box";

interface Props {
  title: string;
  titleValue?: string;
  Icon: React.FC;
  extraProps?: any;
}

let pkFormatter = new Intl.NumberFormat("en-PK", {
  style: "currency",
  currency: "PKR",
});

const DashboardSummaryCard = (props: Props) => {
  const { title, titleValue, Icon, extraProps } = props;

  const renderExtraPropsCurrency = (val) => {
    return (
      <Box sx={{display:"flex", gap: 1}}>
        
        <Box>{pkFormatter.format(val)}</Box>
      </Box>
    )
  }

  const renderExtraProps = () => {
    return (
      <Box sx={{display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
        <Box sx={{display:"flex", flexDirection: "column"}}>
            <Box sx={{fontSize:"12px"}}>{extraProps.key1}</Box>
            <Box sx={{color:"#6631F7", fontSize:"16px", fontWeight:"600"}}>{extraProps.isCurrency ? renderExtraPropsCurrency(extraProps.value1) : extraProps.value1}</Box>
        </Box>
        <Box sx={{display:"flex", flexDirection: "column"}}>
        <Box sx={{fontSize:"12px"}}>{extraProps.key2}</Box>
            <Box sx={{color:"#6631F7", fontSize:"16px", fontWeight:"600"}}>{extraProps.isCurrency ? renderExtraPropsCurrency(extraProps.value2) : extraProps.value2}</Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        borderRadius: "8px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        display: "flex",
        flexDirection:"column",
        justifyContent: "space-between",
       
        padding: "1.5rem",
      }}
    >
      {/*Icon container*/}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Icon />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box
            sx={{
              color: "#111111",
              fontWeight: '600'
            }}
          >
            {title}
          </Box>
          <Box
            sx={{
              color: "#111111",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {titleValue}
          </Box>
        </Box>
      </Box>
      {extraProps && renderExtraProps()}
    </Box>
  );
};

export { DashboardSummaryCard };
