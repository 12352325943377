import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { merchantsActions } from "&features/merchants/merchants.slice";
import { settlementActions } from "&features/settlement/settlement.slice";
import SelectComponent from "&styled/form/select";
import DatePickerComponent from "&styled/form/date-picker";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";

type ReduxProps = ConnectedProps<typeof connector>;

type MerchantSelectType = {
  label: string;
  value: string;
};
const SettlementFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const {
    getMerchants,
    getSettlement,
    settlementId,
    getMerchantRevenue,
    addSettlement,
  } = props;
  const [merchants, setMerchants] = useState<MerchantSelectType[]>([]);
  const [merchantsWithoutFormat, setMerchantsWithoutFormat] = useState<any[]>(
    []
  );
  const [revenueInfo, setRevenueInfo] = useState({});
  const [data, setData] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    country: "",
    merchantName: "",
    totalRevenue: "",
    channel: "",
    waleeShare: "",
    waleeSharePercentage: "",
    merchantShare: "",
    merchantSharePercentage: "",
    subTotal: "",
    remittanceCharges: "",
    remittanceChargesPercentage: "",
    netTransferAmount: "",
    exchangeRate: "",
    netSettledAmount: "",
    localCurrency: "",
    settledCurrency: "",
    userTypeId: "",
    grossSettledAmount: "",
  });

  const validation = Yup.object().shape({
    country: Yup.string().required("Please select Country"),
    merchantName: Yup.string().required("Please select Merchant"),
    totalRevenue: Yup.string().required("Total Revenue can not be empty"),
    channel: Yup.string().required("Please provide channel of transfer"),
    waleeShare: Yup.string().required("Please provide Walee share"),
    waleeSharePercentage: Yup.string().required(
      "Please provide Walee share Percent"
    ),
    merchantShare: Yup.string().required("Please provide Merchant share"),
    merchantSharePercentage: Yup.string().required(
      "Please provide Merchant share Percent"
    ),
    subTotal: Yup.string().required("Sub total can not be empty"),
    remittanceCharges: Yup.string().required(
      "Remittance charges can not be empty"
    ),
    remittanceChargesPercentage: Yup.string().required(
      "Please provide Remittance percentage"
    ),
    netTransferAmount: Yup.string().required(
      "Net transfer amount can not be empty"
    ),
    exchangeRate: Yup.string().required("Please provide exchange rate"),
    netSettledAmount: Yup.string().required(
      "Please provide net settled amount"
    ),
    localCurrency: Yup.string().required(
      "Please provide local currency (e.g PKR)"
    ),
    settledCurrency: Yup.string().required(
      "Please provide foreign currency (e.g. USD)"
    ),
  });

  const fetchMerchants = useCallback(async () => {
    try {
      const { payload } = await getMerchants();
      const formatted: MerchantSelectType[] = [];
      payload.forEach((item) => {
        formatted.push({ label: item.name, value: item.name });
      });
      setMerchantsWithoutFormat(payload);
      setMerchants(formatted);
    } catch (ex) {
      alert("Something went wrong");
      console.log(ex);
    }
  }, [getMerchants]);

  const fetchSettlement = useCallback(
    async (id) => {
      try {
        const { payload: settlement } = await getSettlement(id);
        delete settlement.transactionDate;
        delete settlement.__v;
        settlement["merchantName"] = settlement.userTypeId;
        delete settlement.userTypeId;
        // setData(settlement);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getSettlement]
  );

  useEffect(() => {
    fetchMerchants();
    const id = settlementId;
    if (id === "new") return;
    fetchSettlement(id);
  }, [settlementId, fetchMerchants, fetchSettlement]);

  // Fetch revenue info
  const fetchRevenue = async (e, formik) => {
    formik.setFieldValue("merchantName", e.target.value);
    const params = {
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
      country: formik.values.country,
      merchantName: e.target.value,
    };
    console.log("Params >>> :", params);
    const { payload } = await getMerchantRevenue(params);
    const merchant = merchantsWithoutFormat.find(
      (mer) => mer.name === e.target.value
    );
    if (payload.length === 0) {
      setData((prevStat) => ({
        ...prevStat,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        merchantName: e.target.value,
        country: formik.values.country,
        userTypeId: merchant._id,
      }));
      return;
    }
    const revShare = payload[0];

    setData((prevStat) => ({
      ...prevStat,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      totalRevenue: revShare?.totalAmount ? revShare?.totalAmount : 0,
      waleeShare: revShare.waleeShareValue,
      waleeSharePercentage: revShare.waleeShare,
      merchantShare: revShare.merchantShareValue,
      merchantSharePercentage: revShare.merchantShare,
      subTotal: revShare.merchantShareValue,
      merchantName: e.target.value,
      country: formik.values.country,
      userTypeId: merchant._id,
    }));
  };

  const setRemittance = (e, formik) => {
    formik.setFieldValue("remittanceChargesPercentage", e);
    const netValue = data.subTotal;
    const remitCharges = (parseInt(netValue) * parseInt(e)) / 100;
    const netTransfer = parseInt(data.subTotal) - remitCharges;
    setData((prevState) => ({
      ...prevState,
      remittanceCharges: remitCharges.toString(),
      remittanceChargesPercentage: e,
      netTransferAmount: netTransfer.toString(),
      channel: formik.values.channel,
    }));
  };

  const setExchangeRate = (e, formik) => {
    formik.setFieldValue("exchangeRate", e);
    const netSettle = parseInt(data.netTransferAmount) / parseInt(e);
    const grossSettle = parseInt(data.totalRevenue) / parseInt(e);
    setData((prevState) => ({
      ...prevState,
      exchangeRate: e,
      netSettledAmount: netSettle.toString(),
      grossSettledAmount: grossSettle.toString(),
    }));
  };

  const handleSubmit = async (vals) => {
    await addSettlement(vals);
    history.push("/settlements");
  };
  const countries = [
    { label: "Pakistan", value: "Pakistan" },
    { label: "China", value: "China" },
    { label: "Singapore", value: "Singapore" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" },
  ];
  return (
    <>
      <PageHeader title="Settlement Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: "80vh",
          overflow: "auto",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={2} xs={12}>
                    <DatePickerComponent
                      value={formik.values.dateFrom}
                      label="Revenue Form"
                      onChange={(val) => formik.setFieldValue("dateFrom", val)}
                    />
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <DatePickerComponent
                      value={formik.values.dateTo}
                      label="Revenue To"
                      onChange={(val) => formik.setFieldValue("dateTo", val)}
                    />
                  </Grid>
                  <Grid item lg={8}></Grid>
                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.country}
                      onSelect={formik.handleChange("country")}
                      menuItems={countries}
                      placeHolder="Select Country"
                      hasError={!!formik.errors.country}
                      errorMessage={formik.errors.country as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <SelectComponent
                      value={formik.values.merchantName}
                      onSelect={(e) => fetchRevenue(e, formik)}
                      menuItems={merchants}
                      placeHolder="Select merchantName"
                      hasError={!!formik.errors.merchantName}
                      errorMessage={formik.errors.merchantName as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Total Collected Revenue"}
                      disabled
                      value={formik.values.totalRevenue}
                      handleTextChange={formik.handleChange("totalRevenue")}
                      hasError={!!formik.errors.totalRevenue}
                      errorMessage={formik.errors.totalRevenue as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Walee Share"}
                      disabled
                      value={formik.values.waleeShare}
                      handleTextChange={formik.handleChange("waleeShare")}
                      hasError={!!formik.errors.waleeShare}
                      errorMessage={formik.errors.waleeShare as string}
                    />
                  </Grid>

                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Walee Share %"}
                      value={formik.values.waleeSharePercentage}
                      handleTextChange={formik.handleChange(
                        "waleeSharePercentage"
                      )}
                      disabled
                      hasError={!!formik.errors.waleeSharePercentage}
                      errorMessage={
                        formik.errors.waleeSharePercentage as string
                      }
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Merchant Share"}
                      disabled
                      value={formik.values.merchantShare}
                      handleTextChange={formik.handleChange("merchantShare")}
                      hasError={!!formik.errors.merchantShare}
                      errorMessage={formik.errors.merchantShare as string}
                    />
                  </Grid>

                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Merchant Share %"}
                      value={formik.values.merchantSharePercentage}
                      handleTextChange={formik.handleChange(
                        "merchantSharePercentage"
                      )}
                      disabled
                      hasError={!!formik.errors.merchantSharePercentage}
                      errorMessage={
                        formik.errors.merchantSharePercentage as string
                      }
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Sub Total"}
                      disabled
                      value={formik.values.subTotal}
                      handleTextChange={formik.handleChange("subTotal")}
                      hasError={!!formik.errors.subTotal}
                      errorMessage={formik.errors.subTotal as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Channel"}
                      value={formik.values.channel}
                      handleTextChange={formik.handleChange("channel")}
                      hasError={!!formik.errors.channel}
                      errorMessage={formik.errors.channel as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Remittance %"}
                      value={formik.values.remittanceChargesPercentage}
                      handleTextChange={(e) => setRemittance(e, formik)}
                      hasError={!!formik.errors.remittanceChargesPercentage}
                      errorMessage={
                        formik.errors.remittanceChargesPercentage as string
                      }
                    />
                  </Grid>
                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Remittance Charges"}
                      disabled
                      value={formik.values.remittanceCharges}
                      handleTextChange={formik.handleChange(
                        "remittanceCharges"
                      )}
                      hasError={!!formik.errors.remittanceCharges}
                      errorMessage={formik.errors.remittanceCharges as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Net Transfer Amount"}
                      disabled
                      value={formik.values.netTransferAmount}
                      handleTextChange={formik.handleChange(
                        "netTransferAmount"
                      )}
                      hasError={!!formik.errors.netTransferAmount}
                      errorMessage={formik.errors.netTransferAmount as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Exchange Rate"}
                      value={formik.values.exchangeRate}
                      handleTextChange={(e) => setExchangeRate(e, formik)}
                      hasError={!!formik.errors.exchangeRate}
                      errorMessage={formik.errors.exchangeRate as string}
                    />
                  </Grid>
                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Net Settled Amount"}
                      disabled
                      value={formik.values.netSettledAmount}
                      handleTextChange={formik.handleChange("netSettledAmount")}
                      hasError={!!formik.errors.netSettledAmount}
                      errorMessage={formik.errors.netSettledAmount as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Local Currency"}
                      value={formik.values.localCurrency}
                      handleTextChange={formik.handleChange("localCurrency")}
                      hasError={!!formik.errors.localCurrency}
                      errorMessage={formik.errors.localCurrency as string}
                    />
                  </Grid>
                  <Grid item lg={2} sm={12}>
                    <TextInput
                      placeHolder={"Foreign Currency"}
                      value={formik.values.settledCurrency}
                      handleTextChange={formik.handleChange("settledCurrency")}
                      hasError={!!formik.errors.settledCurrency}
                      errorMessage={formik.errors.settledCurrency as string}
                    />
                  </Grid>
                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  settlementId: ownProps.match.params.settlementId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getMerchants: merchantsActions.getMerchants,
  getSettlement: settlementActions.getSettlement,
  getMerchantRevenue: merchantsActions.getMerchantRevenue,
  addSettlement: settlementActions.addSettlement,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const SettlementFormComponentRedux = connector(SettlementFormComponent);

export { SettlementFormComponentRedux as SettlementFormComponent };
