import React, { ReactNode } from "react";
import Fab from "@mui/material/Fab";

export type FabButtonTypes = {
  expanded?: boolean;
  label: string;
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  inputStyles?: any;
  color?: "inherit" | "default" | "primary" | "secondary";
  disabled?: boolean;
};
export default function FabButton({
  expanded,
  label,
  onClick,
  href,
  children,
  inputStyles,
  color,
  disabled,
}: FabButtonTypes) {
  return (
    <Fab
      aria-controls="basic-menu"
      aria-expanded={expanded ? "true" : undefined}
      aria-haspopup="true"
      aria-label={label}
      onClick={onClick}
      size="small"
      variant="extended"
      href={href}
      sx={{
        ...inputStyles,
        backgroundColor: label === "Delete" ? "#FFE7E7" : "#EDE7FF",
        borderRadius: "10px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: label === "Delete" ? "#FFE7E7" : "#EDE7FF",
        },
      }}
      color={color || "primary"}
      disabled={disabled}
    >
      {children}
    </Fab>
  );
}
