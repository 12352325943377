import * as React from "react";

export default function HistoryIcon() {
  return (
    <svg
      id="Iconly_Light-Outline_Document"
      data-name="Iconly/Light-Outline/Document"
      xmlns="http://www.w3.org/2000/svg"
      width="16.07"
      height="17.805"
      viewBox="0 0 16.07 17.805"
    >
      <g id="Document">
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M0,13.015V4.791A4.425,4.425,0,0,1,4.613,0l6.806,0A4.47,4.47,0,0,1,16.07,4.791v8.224A4.425,4.425,0,0,1,11.457,17.8l-6.806,0A4.47,4.47,0,0,1,0,13.015ZM4.618,1.345A3.109,3.109,0,0,0,1.327,4.791v8.224a3.113,3.113,0,0,0,3.323,3.45l6.8,0a3.109,3.109,0,0,0,3.292-3.446V4.791A3.113,3.113,0,0,0,11.42,1.342ZM4.863,13.39a.67.67,0,0,1,0-1.341H11.25a.67.67,0,0,1,0,1.341Zm0-3.743a.671.671,0,0,1,0-1.342H11.25a.671.671,0,0,1,0,1.342Zm0-3.735a.671.671,0,0,1,0-1.342H7.3a.671.671,0,0,1,0,1.342Z"
          fill="#6631f7"
        />
      </g>
    </svg>
  );
}
