import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import EditIcon from "&assets/icons/edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";
import { rolesActions } from "./roles.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const RolesComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getRoles } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Role Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.modifiedDate).format("DD MMM YYYY"),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    (async () => {
      await getRoles();
    })();
  }, [getRoles]);

  const renderButtons = (row) => {
    return <Box sx={{ display: "flex", gap: 2 }}>{renderBtnEdit(row)}</Box>;
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/roles/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };

  return (
    <>
      <PageHeader
        title="Roles"
        onAdd={() => history.push("/roles/form/new")}
        addText="Create Role"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.roles,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getRoles: rolesActions.getRoles,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const RolesComponentRedux = connector(RolesComponent);

export { RolesComponentRedux as RolesComponent };
