import React, { useState } from "react";
import Box from "@mui/material/Box";
import { currencies } from "&config/currencies";
import SelectComponent from "&styled/form/select";
import { SelectChangeEvent } from "@mui/material/Select";
import DatePickerComponent from "&styled/form/date-picker";
import CountriesWithFlags from "&styled/countries-with-flags";
import { SubmitButton } from "&styled/button/button.component";
import CreateIcon from "&assets/icons/create";


type PageProps = {
  title: string;
  onSearch?: Function;
  onAdd?: Function | null;
  addText?: string | null;
  type?: string;
  hasPrint?: boolean;
  onPrint?: Function;
  getDatesValues?: Function;
  defaultCurrency?: string;
  vendors?: { _id: string; name: string }[];
  selectedVendor?: string;
  balance?: number;
  balanceText?: string;
  onVendorChange?: (event: SelectChangeEvent) => void;
};

export default function PageHeader({
  title,
  onSearch,
  type,
  onAdd,
  hasPrint,
  onPrint,
  getDatesValues,
  defaultCurrency,
  addText,
  vendors,
  balance,
  balanceText,
  selectedVendor,
  onVendorChange
}: PageProps) {
  const [currency, setCurrency] = useState(defaultCurrency || "USD");
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [country, setCountry] = useState("PK");

  const onCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  const handleFromDate = (newValue) => {
    setFromDate(newValue);
    getDatesValues && getDatesValues(newValue, "dateFrom")
  };
  const handleToDate = (newValue) => {
    setToDate(newValue);
    getDatesValues && getDatesValues(newValue, "dateTo")
  };
  const onCountrySelect = (code) => {
    setCountry(code);
  };

  const makeSearch = async () => {
    let formattedCountry = "";
    if (country === "PK") {
      formattedCountry = "Pakistan";
    }
    const vals = {
      dateFrom: fromDate,
      dateTo: toDate,
      country: formattedCountry,
      currency,
    };
    if (onSearch) {
      await onSearch(vals);
    }
  };
  const renderTitle = () => {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <CreateIcon />
        <Box>{`${addText || "Create Account"}`} </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ color: "#3C3C3C", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </Box>
      {type && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <SelectComponent
            menuItems={currencies}
            value={currency}
            onSelect={onCurrencyChange}
          />
          {type === "transaction" && (
            <CountriesWithFlags value={country} onSelect={onCountrySelect} />
          )}

          <DatePickerComponent
            label="Date From"
            value={fromDate}
            onChange={handleFromDate}
          />

          <DatePickerComponent
            label="Date To"
            value={toDate}
            onChange={handleToDate}
          />
          <Box sx={{ width: "50%" }}>
            {" "}
            <SubmitButton
              title="Search"
              handlePress={makeSearch}
              styles={{ height: "100%" }}
            />
          </Box>
        </Box>
      )}
      {!type && !hasPrint && onAdd && (
        <Box sx={{ width: "13%" }}>
          <SubmitButton
            title={renderTitle()}
            handlePress={onAdd ? onAdd : () => { }}
          />
        </Box>
      )}
      {hasPrint && (
        <Box sx={{ width: "15%" }}>
          <SubmitButton
            title="Print"
            handlePress={onPrint ? () => onPrint("page") : () => { }}
          />
        </Box>
      )}
      {balance && balanceText && (
            <span style={{marginLeft: "30rem" }}>
              <span style={{ fontWeight: "bold" }}>
                {balanceText}:
              </span>
              <span style={{ color: "#6631F7", fontWeight: "bold", }}>  PKR {balance}</span>

            </span>)
            }
      {vendors && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          

          <div style={{ width: '300px' }}> {/* apply the style to the div */}
            <SelectComponent
              menuItems={[
                { value: "Select Vendor", label: "Select Vendor" },
                ...vendors.map((vendor) => ({
                  value: vendor.name,
                  label: vendor.name,
                })),
              ]}
              value={selectedVendor || "Select Vendor"}
              onSelect={(event: SelectChangeEvent) =>
                onVendorChange && onVendorChange(event)
              }
            />
          </div>
        </Box>
        // </Box>
      )}

    </Box>
  );
}
