import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { usersActions } from "&features/users/users.slice";

import { SubmitButton } from "&styled/button/button.component";
import {
  TextInput,
  PasswordInput,
} from "&styled/textField/textField.component";
import { successAlert } from "&config/swalGenerator";
import SnackbarComponent from "&styled/snackBar/snackbar.component";

type ReduxProps = ConnectedProps<typeof connector>;

const AccountProfileFormComponent = (props: ReduxProps) => {
  const { getProfile, editProfile } = props;

  const [error, setError] = useState<string>("");
  const [data, setData] = useState({
    _id: "",
    name: "",
    userName: "",
    password: "123456",
    email: "",
  });

  const validation = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short name.")
      .max(50, "Too long name")
      .required("Please provide valid full name"),

    email: Yup.string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Please provide valid email address"),
  });

  const fetchUser = useCallback(async () => {
    try {
      const { payload: user } = await getProfile();
      setData((prevState) => ({
        ...prevState,

        _id: user._id,
        name: user.name,
        userName: user.userName,
        email: user.email,
      }));
    } catch (ex) {
      console.log(ex);
      alert("Something went wrong");
    }
  }, [getProfile]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleSubmit = async (vals) => {
    const { payload } = await editProfile(vals);
    if (payload.errors) {
      setError(payload.errors[0].message);
    } else {
      successAlert("Success", "Profile updated successfully");
    }
  };

  return (
    <>
      <PageHeader title="Account Profile" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Full Name"}
                      value={formik.values.name}
                      handleTextChange={formik.handleChange("name")}
                      hasError={!!formik.errors.name}
                      errorMessage={formik.errors.name as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>
                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"User Name"}
                      value={formik.values.userName}
                      handleTextChange={formik.handleChange("userName")}
                      disabled={true}
                      hasError={!!formik.errors.userName}
                      errorMessage={formik.errors.userName as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <PasswordInput
                      placeHolder={"Password"}
                      required={false}
                      disabled={true}
                      value={formik.values.password}
                      handleTextChange={formik.handleChange("password")}
                      hasError={!!formik.errors.password}
                      errorMessage={formik.errors.password as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} sm={12}>
                    <TextInput
                      placeHolder={"Email"}
                      required={false}
                      value={formik.values.email}
                      handleTextChange={formik.handleChange("email")}
                      hasError={!!formik.errors.email}
                      errorMessage={formik.errors.email as string}
                    />
                  </Grid>

                  <Grid item lg={8} sm={12}></Grid>

                  <Grid item lg={4} marginTop={"3rem"}>
                    <SubmitButton
                      title="Save"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
      {error && <SnackbarComponent type="error" message={error} />}
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  userId: ownProps.match.params.userId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getProfile: usersActions.getProfile,
  editProfile: usersActions.editProfile,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AccountProfileFormComponentRedux = connector(AccountProfileFormComponent);

export { AccountProfileFormComponentRedux as AccountProfileFormComponent };
