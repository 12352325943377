import * as React from "react";

export default function DashboardIcon() {
  return (
    <svg
      id="Iconly_Light-outline_Category"
      data-name="Iconly/Light-outline/Category"
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <g id="Category" transform="translate(0 0)">
        <path
          id="Category-2"
          data-name="Category"
          d="M11.888,17a2.54,2.54,0,0,1-2.529-2.546v-2.6A2.541,2.541,0,0,1,11.888,9.3h2.583A2.54,2.54,0,0,1,17,11.85v2.6A2.54,2.54,0,0,1,14.472,17Zm-1.343-5.15v2.6a1.354,1.354,0,0,0,1.343,1.36h2.583a1.353,1.353,0,0,0,1.343-1.36v-2.6a1.353,1.353,0,0,0-1.343-1.361H11.888A1.354,1.354,0,0,0,10.545,11.85ZM2.529,17A2.54,2.54,0,0,1,0,14.454v-2.6A2.541,2.541,0,0,1,2.529,9.3H5.112A2.541,2.541,0,0,1,7.641,11.85v2.6A2.54,2.54,0,0,1,5.112,17ZM1.186,11.85v2.6a1.354,1.354,0,0,0,1.343,1.36H5.112a1.354,1.354,0,0,0,1.343-1.36v-2.6a1.354,1.354,0,0,0-1.343-1.361H2.529A1.354,1.354,0,0,0,1.186,11.85ZM11.888,7.7A2.54,2.54,0,0,1,9.359,5.151v-2.6A2.54,2.54,0,0,1,11.888,0h2.583A2.54,2.54,0,0,1,17,2.546v2.6A2.54,2.54,0,0,1,14.472,7.7ZM10.545,2.546v2.6a1.354,1.354,0,0,0,1.343,1.36h2.583a1.353,1.353,0,0,0,1.343-1.36v-2.6a1.353,1.353,0,0,0-1.343-1.36H11.888A1.354,1.354,0,0,0,10.545,2.546ZM2.529,7.7A2.54,2.54,0,0,1,0,5.151v-2.6A2.54,2.54,0,0,1,2.529,0H5.112A2.54,2.54,0,0,1,7.641,2.546v2.6A2.54,2.54,0,0,1,5.112,7.7ZM1.186,2.546v2.6a1.354,1.354,0,0,0,1.343,1.36H5.112a1.354,1.354,0,0,0,1.343-1.36v-2.6a1.354,1.354,0,0,0-1.343-1.36H2.529A1.354,1.354,0,0,0,1.186,2.546Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
}
