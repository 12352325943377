import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionComponent({label, children}) {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={label?.replaceAll(" ", "_")}
        >
          <Typography>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
         {children}
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
