import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import ExportButton from "../exportButton";
import PageHeader from "&styled/page-header/pageHeader";
import { RootState } from "&store/store";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { loanRequestsActions } from "../loanRequests.slice";
import { getCurrentUser } from "&config/getCurrentUser";
import SearchIcon from "&assets/icons/search";
import SmallFileIcon from "&assets/icons/small-file";

type ReduxProps = ConnectedProps<typeof connector>;

interface State {
  query: string;
  from: string;
  to: string;
}

const FileRecordComponent = (props: ReduxProps) => {
  const { state, getFileRecords, token } = props;
  const { fileRecs } = state;
  const user: any = getCurrentUser(token);
  const { userTypeId } = user;
  const [search, setSearch] = useState({
    query: "",
    from: new Date(),
    to: new Date(),
  });
  const [pageSize, setPageSize] = React.useState(25);

  const columns: GridColDef[] = [
    {
      field: "fileName",
      headerName: "File Name",
      renderCell: ({ row }) => (
        <Box
          sx={{ color: "#6631F7", display: "flex", gap: 1, fontSize: "12px" }}
        >
          <SmallFileIcon />
          {row.originalName}
        </Box>
      ),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "updatedRecords",
      headerName: "RECORD UPDATE",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "noOfTransactions",
      headerName: "NO. OF TRANSACTION",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdDate",
      headerName: "UPLOADED DATE",
      renderCell: ({ row }) =>
        moment(row.createdDate).format("DD MMM YYYY HH:mm A"),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      const params = {
        dateFrom: search.from,
        dateTo: search.to,
      };
      await getFileRecords(params);
    })();
  }, [getFileRecords, search.from, search.to]);

  const handleChange = (prop: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const handleDateChange = (val, type) => {
    setSearch((prevState) => ({ ...prevState, [type]: val }));
  };

  const renderExportBtn = () => {
    return <ExportButton data={fileRecs} name="File Record"></ExportButton>;
  };
  const renderCta = () => {
    return (
      <>
        {userTypeId === "Walee" && (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "30%",
              justifyContent: "flex-end",
            }}
          >
            {renderExportBtn()}
          </Box>
        )}
      </>
    );
  };
  return (
    <>
      <PageHeader title="File Record" renderCta={renderCta} />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            {/*Search filters*/}
            <Box sx={{ marginY: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item lg={8}>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    {/*<InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>*/}
                    <OutlinedInput
                      id="outlined-adornment-search"
                      value={search.query}
                      size="small"
                      onChange={handleChange("query")}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      placeholder="Search"
                    />
                  </FormControl>
                </Grid>

                <Grid item lg={2} marginTop={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={search.from}
                      onChange={(val) => handleDateChange(val, "from")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="From"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={2} marginTop={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={search.to}
                      onChange={(val) => handleDateChange(val, "to")}
                      renderInput={(params) => (
                        <TextField {...params} size="small" placeholder="To" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
            <div style={{ height: 650, width: "100%" }}>
              <DataGrid
                rows={fileRecs}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.loanRequests,
  token: state.login.token,
  uploadProgress: state.loanRequests.uploadProgress,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getFileRecords: loanRequestsActions.getFileRecords,
  changeStatus: loanRequestsActions.changeStatus,
  fileUpload: loanRequestsActions.fileUpload,
  deleteFile: loanRequestsActions.deleteFile,
  fileParsed: loanRequestsActions.fileParsed,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const FileRecordComponentRedux = connector(FileRecordComponent);

export { FileRecordComponentRedux as FileRecordComponent };
