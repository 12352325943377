import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Users } from "./users.type";

/**
 * Initial state object
 */
const initialState: Users = {
  data: [],
};

const getUsers = createAsyncThunk(
  "users/getUsersCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from merchants :", response.data);
      dispatch(usersActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getUser = createAsyncThunk(
  "users/getUserCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/get-by-id/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from user :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addUser = createAsyncThunk(
  "users/addUserCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from adding user :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const editUser = createAsyncThunk(
  "users/editUserCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing user :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const editUserStatus = createAsyncThunk(
  "users/editUserStatusCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/edit/toggle-user-status/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      status : body.status
    }
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), params, { headers })
      );
      console.log("Success response from editing user status :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteUser = createAsyncThunk(
  "users/addUserCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/delete/${body}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(usersActions.setDeleteUser(body));
      console.log("Success response from delete user :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// get profile
const getProfile = createAsyncThunk(
  "profiles/getProfileCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/profiles/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from profile :", response.data);
      dispatch(usersActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// Edit profile
const editProfile = createAsyncThunk(
  "profile/editProfileCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/profiles/edit`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing profile :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// Change password
const changePassword = createAsyncThunk(
  "users/changePasswordCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/users/edit/status/change-password`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing profile :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const usersSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "users",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setUsers: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setChangeStatus :(state, action)=>{
      console.log(action.payload)
    },
    setDeleteUser: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

  extraReducers: (builder) => {
  
    builder.addCase(editUserStatus.fulfilled, (state, {payload}) => {
      const {user}= payload;
      state.data = state.data.map((u)=>u._id === user.id ? {...u, status: user.status} : u)
    });
   
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const usersReducer = usersSlice.reducer;

export const usersActions = {
  ...usersSlice.actions,
  getUsers,
  getUser,
  addUser,
  editUser,
  deleteUser,
  getProfile,
  editProfile,
  changePassword,
  editUserStatus
};
