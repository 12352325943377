import * as React from "react";

export default function PaperIcon() {
  return (
    <svg
      id="Iconly_Light-outline_Paper"
      data-name="Iconly/Light-outline/Paper"
      xmlns="http://www.w3.org/2000/svg"
      width="14.128"
      height="16.458"
      viewBox="0 0 14.128 16.458"
    >
      <g id="Paper" transform="translate(0 0)">
        <path
          id="Paper-2"
          data-name="Paper"
          d="M3.8,16.458A3.789,3.789,0,0,1,0,12.6V3.721A3.636,3.636,0,0,1,1.126,1.1,3.817,3.817,0,0,1,3.8.01H8.982a.628.628,0,0,1,.22,0H9.31A.625.625,0,0,1,9.758.2l4.2,4.371A.623.623,0,0,1,14.128,5V12.6a3.839,3.839,0,0,1-3.711,3.862Zm0-15.205A2.546,2.546,0,0,0,1.243,3.721V12.6a2.646,2.646,0,0,0,.736,1.863,2.516,2.516,0,0,0,1.819.756h6.619a2.406,2.406,0,0,0,1.731-.784,2.708,2.708,0,0,0,.737-1.835V5.785l-.725,0h-.941a2.754,2.754,0,0,1-2.743-2.6l0-.156V1.252ZM9.714,3.032a1.511,1.511,0,0,0,1.508,1.511H12.2L9.714,1.949Zm-5.25,8.822a.621.621,0,0,1-.084-1.237l.084-.005H8.935a.621.621,0,0,1,.084,1.237l-.084.005Zm0-4.1a.621.621,0,0,1-.084-1.237l.084-.006H7.243a.621.621,0,0,1,.084,1.237l-.084.006Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
}
