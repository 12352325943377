import * as React from "react";

export default function TicketIcon() {
  return (
    <svg
      id="Iconly_Light-outline_Ticket_Star"
      data-name="Iconly/Light-outline/Ticket Star"
      xmlns="http://www.w3.org/2000/svg"
      width="16.57"
      height="15.052"
      viewBox="0 0 16.57 15.052"
    >
      <g id="Ticket_Star" data-name="Ticket Star" transform="translate(0)">
        <path
          id="Ticket_Star-2"
          data-name="Ticket Star"
          d="M3.431,15.052A3.436,3.436,0,0,1,0,11.619V9.382a.622.622,0,0,1,.621-.621A1.235,1.235,0,0,0,1.855,7.527,1.172,1.172,0,0,0,.621,6.366.622.622,0,0,1,0,5.744V3.432A3.436,3.436,0,0,1,3.433,0h9.7a3.436,3.436,0,0,1,3.431,3.432V5.671a.621.621,0,0,1-.621.622l-.119.006a1.234,1.234,0,0,0,.119,2.461.622.622,0,0,1,.621.621v2.237a3.436,3.436,0,0,1-3.432,3.433ZM1.244,3.433V5.2l.086.022A2.371,2.371,0,0,1,3.094,7.382l0,.145A2.489,2.489,0,0,1,1.325,9.9l-.083.021v1.7A2.192,2.192,0,0,0,3.294,13.8l.138,0h9.706a2.193,2.193,0,0,0,2.19-2.19v-1.7L15.245,9.9a2.477,2.477,0,0,1-1.769-2.229l0-.146a2.487,2.487,0,0,1,1.772-2.375l.083-.022v-1.7a2.192,2.192,0,0,0-2.051-2.185l-.138,0h-9.7A2.192,2.192,0,0,0,1.244,3.433Zm4.923,6.7a.789.789,0,0,1-.214-.6l.01-.089.22-1.284L5.251,7.26A.805.805,0,0,1,5.606,5.9L5.7,5.886,6.985,5.7l.578-1.171a.805.805,0,0,1,1.444,0L9.584,5.7l1.291.187a.806.806,0,0,1,.508,1.3l-.063.069-.934.907.221,1.285a.788.788,0,0,1-.174.651.813.813,0,0,1-.621.291.8.8,0,0,1-.293-.056L9.437,10.3,8.284,9.7,7.131,10.3a.8.8,0,0,1-.373.093A.815.815,0,0,1,6.167,10.136ZM8.494,8.408l.08.035.657.346-.125-.733a.619.619,0,0,1,.121-.485l.058-.065.53-.518-.733-.106a.627.627,0,0,1-.424-.265l-.044-.075-.33-.667-.329.667a.622.622,0,0,1-.468.34l-.734.106.531.518a.624.624,0,0,1,.187.464l-.008.086-.126.732L8,8.443a.62.62,0,0,1,.5-.035Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
}
