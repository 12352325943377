import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { OneTinePurchase } from "./oneTinePurchase.type";

/**
 * Initial state object
 */
const initialState: OneTinePurchase = {
  purchases: {
    current: 1,
    data: [],
    pages: 0,
    total: 0,
  },
};

const getPurchases = createAsyncThunk(
  "purchases/getSubscriptionsCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/onetime-purchase/get?page=${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from onetime purchase :", response.data);
      dispatch(oneTinePurchaseActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const searchPurchases = createAsyncThunk(
  "purchases/searchSubscriptionsCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/onetime-purchase/search?page=${arg.page}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    delete arg.page;
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), arg, { headers })
      );
      console.log(
        "Success response from search onetime purchase :",
        response.data
      );
      dispatch(oneTinePurchaseActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const oneTinePurchaseSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "oneTinePurchase",

  initialState: initialState,

  reducers: {
    setOneTinePurchase: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.purchases = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },

  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getPurchases.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getPurchases.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getPurchases.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const oneTinePurchaseReducer = oneTinePurchaseSlice.reducer;

export const oneTinePurchaseActions = {
  ...oneTinePurchaseSlice.actions,
  getPurchases,
  searchPurchases,
};
