
import * as React from 'react';
import Box from '@mui/material/Box'
import EasypaisaImg from "../../../images/easypaisa.png";

export default function EasypaisaInstructions(){
    return(
        <>
        <Box sx={{border: '1px solid #eee', borderRadius:'5px', padding:'1rem', width:'90%'}}>
            <Box sx={{display:"flex", gap:2}}>
                <Box sx={{width:"30%"}}>
                <Box sx={{width:"4rem", height:"4rem", border:"1px solid #eee", borderRadius:"100px", position:"relative"}}>
                    <img src={EasypaisaImg} alt="easypaisa" style={{width:"6rem", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}/>
                </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                    <Box sx={{fontWeight:"bold", fontSize:"1rem", marginBottom:"0.5rem"}}>For Telenor Users</Box>
                    <Box sx={{color:"#777"}}>Unlock your phone and enter the <span style={{color:"#6631f7"}}>5-digit MPIN</span> in prompt you received. Telenor users can also confirm through <span style={{color:"#6631f7"}}>Easypaisa</span> app</Box>
                </Box>
            </Box>
        </Box>
        <Box sx={{border: '1px solid #eee', borderRadius:'5px', padding:'1rem',width:'90%', marginTop:"1rem"}}>
            <Box sx={{display:"flex", gap:2}}>
                <Box sx={{width:"4rem", height:"4rem", border:"1px solid #eee", borderRadius:"100px", position:"relative"}}>
                    <img src={EasypaisaImg} alt="easypaisa" style={{width:"6rem", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}/>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                    <Box sx={{fontWeight:"bold",fontSize:"1rem", marginBottom:"0.5rem"}}>For Other Mobile Operators</Box>
                    <Box sx={{color:"#777"}}>Follow these steps</Box>
                    <Box sx={{display:"flex", alignItems:'center', gap:1}}>
                        <Box sx={{width:'0.5rem', height:"0.5rem", border:'1px solid #6631f7', borderRadius:"100px"}}></Box>
                        <Box sx={{color:"#777"}}>Login to <span style={{color:"#6631f7"}}>Easypaisa</span> App</Box>
                    </Box>
                    <Box sx={{display:"flex", gap:1, alignItems:'center',}}>
                        <Box sx={{width:'0.5rem', height:"0.5rem", border:'1px solid #6631f7', borderRadius:"100px"}}></Box>
                        <Box sx={{color:"#777"}}>Go to <span style={{color:"#6631f7"}}>My Profile</span></Box>
                    </Box>
                    <Box sx={{display:"flex", gap:1, alignItems:'center',}}>
                        <Box sx={{width:'0.5rem', height:"0.5rem", border:'1px solid blue', borderRadius:"100px"}}></Box>
                        <Box sx={{color:"#777"}}>Go to <span style={{color:"#6631f7"}}>My Profile</span> to <span style={{color:"#6631f7"}}>Confirm</span></Box>
                   </Box>
                   
                </Box>
            </Box>
        </Box>
        </>
        
    )
} 
