import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Carriers } from "./carriers.type";

/**
 * Initial state object
 */
const initialState: Carriers = {
  data: [],
};

const getCarriers = createAsyncThunk(
  "carriers/getCarriersCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carriers/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from carriers :", response.data);
      dispatch(carriersActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const getCarrier = createAsyncThunk(
  "carrier/getCarrierCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carriers/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addCarrier = createAsyncThunk(
  "carrier/addCarrierCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carriers/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from adding carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editCarrier = createAsyncThunk(
  "carrier/editCarrierCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carriers/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const toggleCarrierStatus = createAsyncThunk(
  "carrier/toggleCarrierStatusCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carriers/edit/status/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      dispatch(carriersActions.toggleStatus(body));
      console.log("Success response from toggle carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteCarrier = createAsyncThunk(
  "carrier/deleteCarrierCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/carrier/delete/${body}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(carriersActions.setDeleteCarrier(body));
      console.log("Success response from deleting carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const carriersSlice = createSlice({
  name: "carriers",

  initialState: initialState,

  reducers: {
    setCarriers: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDeleteCarrier: (state, action) => {
      state.data = state.data.filter((val) => val._id !== action.payload);
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    toggleStatus: (state, action) => {
      const index = state.data.map((u) => u._id).indexOf(action.payload._id);
      if (index !== -1) {
        state.data[index].status = action.payload.status;
      }
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getCarriers.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getCarriers.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getCarriers.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const carriersReducer = carriersSlice.reducer;

export const carriersActions = {
  ...carriersSlice.actions,
  getCarriers,
  getCarrier,
  addCarrier,
  editCarrier,
  deleteCarrier,
  toggleCarrierStatus,
};
