import * as React from "react";

export default function MerchantsIcon() {
  return (
    <svg
      id="Iconly_Light-Outline_3_User"
      data-name="Iconly/Light-Outline/3 User"
      xmlns="http://www.w3.org/2000/svg"
      width="17.979"
      height="14.278"
      viewBox="0 0 17.979 14.278"
    >
      <g id="_3_User" data-name="3 User" transform="translate(0)">
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M3.45,11.561c0-2.706,4.169-2.706,5.54-2.706s5.54,0,5.54,2.72c0,2.7-4.025,2.7-5.54,2.7C7.619,14.278,3.45,14.278,3.45,11.561Zm1.239,0C4.689,12.752,7.028,13,8.99,13s4.3-.247,4.3-1.427-2.339-1.444-4.3-1.444S4.689,10.379,4.689,11.561Zm11.133.384a.643.643,0,0,1,.358-.823c.56-.22.56-.462.56-.579,0-.373-.46-.633-1.367-.772a.636.636,0,0,1-.521-.726.626.626,0,0,1,.705-.536c2,.309,2.421,1.276,2.421,2.034a1.862,1.862,0,0,1-1.357,1.77.607.607,0,0,1-.221.042A.621.621,0,0,1,15.822,11.945Zm-14.463.369A1.864,1.864,0,0,1,0,10.544C0,9.787.421,8.819,2.422,8.51a.627.627,0,0,1,.7.536.637.637,0,0,1-.521.726c-.907.139-1.367.4-1.367.772,0,.116,0,.358.56.579a.642.642,0,0,1,.358.823.62.62,0,0,1-.579.411A.606.606,0,0,1,1.359,12.314ZM8.968,7.77A3.678,3.678,0,0,1,6.31,6.626,3.9,3.9,0,0,1,5.218,3.882,3.833,3.833,0,0,1,8.99,0a3.835,3.835,0,0,1,3.772,3.885A3.834,3.834,0,0,1,8.99,7.77ZM6.457,3.885a2.617,2.617,0,0,0,.731,1.841,2.473,2.473,0,0,0,1.781.768l.021.637V6.495a2.484,2.484,0,0,0,1.792-.766,2.631,2.631,0,0,0,.742-1.844,2.535,2.535,0,1,0-5.068,0Zm6.875,2.492a.635.635,0,0,1,.528-.72,1.812,1.812,0,0,0,.04-3.566.637.637,0,0,1-.511-.733A.623.623,0,0,1,14.1.833a3.046,3.046,0,0,1,2.506,3.041A3.069,3.069,0,0,1,14.03,6.921a.642.642,0,0,1-.085.006A.626.626,0,0,1,13.332,6.378Zm-9.383.543A3.069,3.069,0,0,1,1.373,3.875,3.046,3.046,0,0,1,3.88.833a.618.618,0,0,1,.711.526.636.636,0,0,1-.51.733A1.812,1.812,0,0,0,4.12,5.658a.634.634,0,0,1,.528.72.626.626,0,0,1-.613.549A.66.66,0,0,1,3.949,6.921Z"
        />
      </g>
    </svg>
  );
}
