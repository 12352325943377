import * as React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import FileUpload from "&assets/icons/fileUpload";
import { SubmitButton, OutlinedButton } from "&styled/button/button.component";
import FileIcon from "&assets/icons/file";
import LinearWithValueLabel from "./progressBar";
import DeleteIcon from "&assets/icons/delete";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "1px solid #707070",
  borderRadius: "8px",
  p: 1,
};

export default function UploadModal({
  open,
  handleClose,
  handleProceed,
  onChange,
  fileName,
  value,
  fileUploadBtnDisabled,
  uploadView,
  fileUploadSummary,
  fileParsed,
  deleteFile,
  error,
}) {
  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });
 
  const handleDelete = async () => {
    const fileName = fileUploadSummary.fileName;
    try {
      await deleteFile(fileName);
      handleClose();
    } catch (e:any) {}
  };

  const handleFileParse = async () => {
    const { originalName, fileName, size, total, invalidStatus } = fileUploadSummary;
    const params = {
      originalName,
      fileName,
      size,
      total,
      invalidStatus: invalidStatus.length
    };
    try {
      await fileParsed(params);
      window.location.href = "/loan-requests";
      handleClose();
    } catch (e:any) {}
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-modal"
        aria-describedby="file-upload-modal"
      >
        <Box sx={style}>
          {/*Header*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ font: "normal normal 600 20px/30px Poppins" }}>
              Upload
            </Box>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {/*File Upload Area*/}
            {uploadView === "fileUpload" && (
              <label
                htmlFor="file"
                style={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  border: "1px dashed #DCDCDC",
                  borderRadius: "5px",
                  width: "478px",
                  height: "153px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FileUpload />
                <Box sx={{ marginTop: "1rem" }}>
                  Drag and Drop/
                  <span style={{ color: "#6631F7" }}>Upload file</span>
                </Box>
                <input type="file" id="file" hidden onChange={onChange} />
              </label>
            )}
            {/*Progress Bar*/}
            {fileName && uploadView === "fileUpload" && (
              <Box sx={{ marginY: "1rem", width: "100%" }}>
                <Box
                  sx={{
                    color: "#111111",
                    fontSize: "14px",
                    marginLeft: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  {fileName}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    marginTop: "-1rem",
                  }}
                >
                  <FileIcon />
                  <LinearWithValueLabel value={value} />
                </Box>
              </Box>
            )}
            {/*File Info*/}
            {uploadView === "fileInfo" && (
              <Box
                sx={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  padding: "1rem",
                  borderRadius: "5px",
                  width: "478px",
                  height: "270px",
                  backgroundColor: "#F3EFFF",
                }}
              >
                {/*File Info Header*/}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <FileIcon />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          fontSize: "14px",
                          color: "#111111",
                          fontWeight: "bold",
                        }}
                      >
                        {fileUploadSummary.originalName}
                      </Box>
                      <Box sx={{ fontSize: "12px" }}>
                        {(
                          parseInt(fileUploadSummary.size) /
                          (1024 * 1024)
                        ).toFixed(2)}
                        mb
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ cursor: "pointer" }} onClick={handleDelete}>
                    <DeleteIcon />
                  </Box>
                </Box>

                <Box sx={{ padding: "1rem" }}>
                  {/*Total Logs*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Total Log
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {fileUploadSummary.total}
                    </Box>
                  </Box>
                  {/*Suucessful*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Successful
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {fileUploadSummary.Approved}
                    </Box>
                  </Box>
                  {/*Rejected*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Rejected
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {fileUploadSummary.Rejected}
                    </Box>
                  </Box>
                  {/*Pending*/}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Pending
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {fileUploadSummary.invalidStatus.length}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }}
                  >
                    <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Amount
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>
                      {pkFormatter.format(fileUploadSummary.amount)}
                    </Box>
                  </Box>
                  {fileUploadSummary?.invalidStatus?.length > 0 && (
                    <Box
                      sx={{
                        marginY: "1rem",
                        color: "#FF1D1D",
                        fontSize: "12px",
                      }}
                    >
                      Acceptable staus are "Approved" or "Rejected". While this
                      file contains some invalid status which were discarded
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {uploadView === "error" && (
              <Box
                sx={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  padding: "1rem",
                  borderRadius: "5px",
                  width: "478px",
                  height: "230px",
                  color: "#FF1D1D",
                  backgroundColor: "#FFE4E4",
                }}
              >
                <Box>
                  {" "}
                  File has some errors. Please review to fix them and reupload.
                </Box>
                <Box sx={{ marginY: "2rem", fontWeight: "bold" }}>{error}</Box>
              </Box>
            )}
            {!error && (
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  marginTop: "1rem",
                  gap: 2,
                  marginLeft: "auto",
                }}
              >
                <OutlinedButton
                  title="Discard"
                  handlePress={handleClose}
                  disabled={fileUploadBtnDisabled}
                />
                <SubmitButton
                  title="Proceed"
                  handlePress={
                    uploadView === "fileUpload"
                      ? handleProceed
                      : handleFileParse
                  }
                  disabled={fileUploadBtnDisabled}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
