import * as React from "react";

export default function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.017"
      height="10.106"
      viewBox="0 0 14.017 10.106"
    >
      <path
        id="check"
        d="M15.188,6,7.5,13.692,4,10.2"
        transform="translate(-2.586 -4.586)"
        fill="none"
        stroke="#0db94c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
