import * as React from "react";

export default function FileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.9"
      height="34.5"
      viewBox="0 0 27.9 34.5"
    >
      <g
        id="Group_5695"
        data-name="Group 5695"
        transform="translate(-383.25 -430.75)"
      >
        <text
          id="xlc"
          transform="translate(397 458.5)"
          fill="#6631f7"
          fontSize="12"
          fontFamily="Poppins-Medium, Poppins"
          fontWeight="500"
        >
          <tspan x="-8" y="0">
            xlc
          </tspan>
        </text>
        <g id="file" transform="translate(380 429.5)">
          <path
            id="Path_22539"
            data-name="Path 22539"
            d="M18.85,2H7.3A3.3,3.3,0,0,0,4,5.3V31.7A3.3,3.3,0,0,0,7.3,35H27.1a3.3,3.3,0,0,0,3.3-3.3V13.55Z"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_22540"
            data-name="Path 22540"
            d="M13,2V13.55H24.55"
            transform="translate(5.85)"
            fill="none"
            stroke="#6631f7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
