import * as React from "react";

export default function CarriersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.832"
      height="16.584"
      viewBox="0 0 14.832 16.584"
    >
      <g
        id="Iconly_Light-Outline_Swap"
        data-name="Iconly/Light-Outline/Swap"
        transform="translate(14.832) rotate(90)"
      >
        <g id="Swap" transform="translate(0 0)">
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M12.12,14.711h0l0,0h0a.635.635,0,0,1-.069-.059l0,0L8.592,11.241a.615.615,0,0,1,0-.882.644.644,0,0,1,.827-.058l.071.061L11.86,12.7V2.881A.636.636,0,0,1,13.125,2.8l.006.085V12.7L15.5,10.361a.642.642,0,0,1,.9,0,.616.616,0,0,1,.064.812l-.062.07-3.453,3.408h0l0,0a.642.642,0,0,1-.821.059ZM3.459,12.036l-.005-.085V2.134L1.085,4.471a.645.645,0,0,1-.827.062l-.072-.06a.617.617,0,0,1-.063-.812l.062-.07L3.638.183A.634.634,0,0,1,3.9.028h0l.013,0h0l.01,0h.006l.008,0,.008,0,.007,0,.009,0H3.97l.009,0h.183l.014,0h.033l.008,0h.005l.009,0h0a.637.637,0,0,1,.232.106l0,0a.636.636,0,0,1,.063.053l.005,0L7.992,3.591a.617.617,0,0,1,0,.883.644.644,0,0,1-.827.058l-.072-.061L4.723,2.134v9.817a.636.636,0,0,1-1.264.085Z"
          />
        </g>
      </g>
    </svg>
  );
}
