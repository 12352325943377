import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "&store/store";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Box, Card, CardContent } from "@mui/material";
import moment from "moment";

import { vouchersActions } from "../vouchers.slice";
import { getCurrentUser } from "&config/getCurrentUser";

import PageHeader from "&styled/page-header";


type ReduxProps = ConnectedProps<typeof connector>;


const VoucherRequest = (props: ReduxProps) => {
  const history = useHistory();

  const { 
    state,
    getVouchersRequest,
    getMerchantBalance,
    token,
   } = props;
   const user: any = getCurrentUser(token);

  const { request } = state; // Accessing data from Redux state
  const [pageSize, setPageSize] = useState(25);
  const [balance, setBalance] = useState();
 
  const columns: GridColDef[] = [
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service Name",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "reference_no",
      headerName: "Reference No",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row?.responsePayload?.reference_no || "",
    },
    {
      field: "order",
      headerName: "Order ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row?.responsePayload?.order || "",
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => "PKR" || "",
    },
    {
      field: "salesRate",
      headerName: "Total Amount",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => params.row?.financialSummary?.salesRate || "",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {

      await getVouchersRequest();      
      if (user.userTypeId !== "Walee") {

        const merchantAccount: any = await getMerchantBalance();
                
        setBalance(merchantAccount.payload?.data[0]?.availableBalance);
      }
      
    })();
  }, []);


  return (
    <>
      <PageHeader
        title="Vouchers Request"
        balance={balance}
        balanceText="Merchant Balance"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>

            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={request} // Using data from Redux state
                rowHeight={40}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: state.vouchers,
  token: state.login.token,
});

const mapDispatchToProps = {
  getVouchersRequest: vouchersActions.getVoucherRequests,
  getMerchantBalance: vouchersActions.getMerchantBalance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const VouchersRequestRedux = connector(VoucherRequest);

export { VouchersRequestRedux as VouchersRequestComponent };