import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Merchants } from "./merchants.type";

/**
 * Initial state object
 */
const initialState: Merchants = {
  data: [],
};

const getMerchants = createAsyncThunk(
  "merchants/getMerchantsCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from merchants :", response.data);
      dispatch(merchantsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const getMerchant = createAsyncThunk(
  "merchants/getMerchantCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from merchant :", response.data);
      dispatch(merchantsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getMerchantInRedis = createAsyncThunk(
  "merchants/getMerchantInRedisCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/get/user-type-id/from-redis`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from merchant :", response.data);
    
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addMerchant = createAsyncThunk(
  "merchants/addMerchantCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from adding merchant :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editMerchant = createAsyncThunk(
  "merchants/addMerchantCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing merchant :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const updateAccountBalance = createAsyncThunk(
  "merchants/updateAccountBalanceCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/account-balance/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log(
        "Success response from updating account balance :",
        response.data
      );

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const toggleMerchantStatus = createAsyncThunk(
  "carrier/toggleCarrierStatusCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/edit/status/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      dispatch(merchantsActions.toggleStatus(body));
      console.log("Success response from toggle carrier :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteMerchant = createAsyncThunk(
  "merchants/addMerchantCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/delete/${body}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(merchantsActions.setDeleteMerchant(body));
      console.log("Success response from adding merchant :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const getMerchantRevenue = createAsyncThunk(
  "merchants/getMerchantsCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/merchants/add/revenue`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    body.status = "Success";
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log(
        "Success response from getting revenue for merchant :",
        response.data
      );

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const merchantsSlice = createSlice({
  name: "merchants",

  initialState: initialState,

  reducers: {
    setMerchants: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDeleteMerchant: (state, action) => {
      state.data = state.data.filter((val) => val._id !== action.payload);
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    toggleStatus: (state, action) => {
      const index = state.data.map((u) => u._id).indexOf(action.payload._id);
      if (index !== -1) {
        state.data[index].status = action.payload.status;
      }
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getMerchants.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getMerchants.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getMerchants.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const merchantsReducer = merchantsSlice.reducer;

/**
 * Actions hold the same names as reducers.
 * Actions can be dispached using 'useDispacth' hook,
 * or by 'mapDispatchToProps' in the redux 'connect' function
 */
export const merchantsActions = {
  ...merchantsSlice.actions,
  getMerchants,
  getMerchantRevenue,
  getMerchant,
  getMerchantInRedis,
  addMerchant,
  deleteMerchant,
  editMerchant,
  updateAccountBalance,
  toggleMerchantStatus,
};
