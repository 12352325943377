import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { LoanRequests } from "./loanRequests.type";
const FormData = require("form-data");

const url = process.env.REACT_APP_CREDID_LOAN_URL;
const oneTimePurchaseUrl = process.env.REACT_APP_ONE_TIME_PURCHASE_URL;

const initialState: LoanRequests = {
  data: [],
  fileRecs: [],
  uploadProgress: 0,
  autoJobStatus: "start",
  epAutoJobStatus: "start"
};

const getLoanRequests = createAsyncThunk(
  "loanRequests/makeLoanRequestsApiCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      dateFrom: new Date(),
      dateTo: new Date(),
      status: "All",
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), params, { headers })
      );
      console.log("Success response from loan requests :", response.data);
      dispatch(loanRequestsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const searchLoanRequests = createAsyncThunk(
  "loanRequests/searchLoanRequestsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/get/search`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      dateFrom: arg.from,
      dateTo: arg.to,
      status: arg.status,
      query: arg.query,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), params, { headers })
      );
      console.log("Success response from loan requests :", response.data);
      dispatch(loanRequestsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getFileRecords = createAsyncThunk(
  "loanRequests/getFileRecordsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/file-record`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    const params = {
      dateFrom: arg.dateFrom,
      dateTo: arg.dateTo,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), params, { headers })
      );
      console.log("Success response from file rec :", response.data);
      dispatch(loanRequestsActions.setFileRecData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const changeStatus = createAsyncThunk(
  "loanRequests/makeLoanRequestsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/edit/change-status`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(url!.concat(pathname), arg, { headers })
      );
      console.log("Success response from change status :", response.data);
      dispatch(loanRequestsActions.setChangeStatus(arg));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const refreshLoanRequest = createAsyncThunk(
  "loanRequests/makeLoanRequestsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const url = `${oneTimePurchaseUrl}/jazzcash-disbursal/inquire/v2`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url, arg, { headers })
      );
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const fileUpload = createAsyncThunk(
  "loanRequests/fileUploadApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/file/file-upload`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data",
    };
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(loanRequestsActions.setUploadProgress(percentCompleted));
      },
    };

    const form = new FormData();
    form.append("file", arg);
    try {
      /** make api call */
      const response = await axios.post(url!.concat(pathname), form, {
        headers,
        ...config,
      });

      console.log("Success response from change status :", response.data);
      dispatch(loanRequestsActions.setChangeStatus(arg));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const fileParsed = createAsyncThunk(
  "loanRequests/fileParsedApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/file/file-parse`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(url!.concat(pathname), arg, {
          headers,
        })
      );

      console.log("Success response from File Parsed :", response.data);
      dispatch(loanRequestsActions.setChangeStatus(arg));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteFile = createAsyncThunk(
  "loanRequests/deleteFileApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/loan-request/delete/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(url!.concat(pathname), { headers })
      );
      console.log("Success response from delete file :", response.data);
      dispatch(loanRequestsActions.setChangeStatus(arg));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// Process Jazzcash auto jobs
const processAutoJobs = createAsyncThunk(
  "loanRequests/processAutoJobsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/auto-jobs/process-payouts`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(oneTimePurchaseUrl!.concat(pathname), arg, { headers })
      );
      const status = arg.status;
      dispatch(loanRequestsActions.setProgressJobStatus(status === "start" ? "stop" : "start"));
      console.log("Success response from change status :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
// Process Easypaisa auto jobs
const processEasypaisaAutoJobs = createAsyncThunk(
  "loanRequests/processAutoJobsApiCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/auto-jobs/easypaisa/process-payouts`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(oneTimePurchaseUrl!.concat(pathname), arg, { headers })
      );
      const status = arg.status;
      console.log("=== Setting EP Job Status as ", status === "start" ? "stop" : "start")
      dispatch(loanRequestsActions.setProgressEasypaisaJobStatus(status === "start" ? "stop" : "start"));
      console.log("Success response from change status :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// get jazzcash auto job status
const getJobStatus = createAsyncThunk(
  "loanRequests/getJobStatusApiCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/auto-jobs/set-status`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(oneTimePurchaseUrl!.concat(pathname), { headers })
      );
 
      const status = response?.data;

      dispatch(loanRequestsActions.setProgressJobStatus(status?.jcJobStatus?.status === "start" ? "stop" : "start"));
      dispatch(loanRequestsActions.setProgressEasypaisaJobStatus(status?.epJobStatus?.status === "start" ? "stop" : "start"));
      console.log("Success response from change status :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// get easypaisa auto job status
const getEasypaisaJobStatus = createAsyncThunk(
  "loanRequests/getJobStatusApiCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/auto-jobs/set-status/easypaisa`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };

    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(oneTimePurchaseUrl!.concat(pathname), { headers })
      );
      console.log("Response >>> :", response)
      const status = response?.data?.status;
      dispatch(loanRequestsActions.setProgressEasypaisaJobStatus(status === "start" ? "stop" : "start"));
      console.log("Success response from change status :", response.data);
     
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const loanRequestsSlice = createSlice({
 
  name: "loanRequests",

  initialState: initialState,

  reducers: {
    setLoanRequests: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setFileRecData: (state, action) => {
      state.fileRecs = action.payload;
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setProgressJobStatus: (state, action) => {
      state.autoJobStatus = action.payload;
    },
    setProgressEasypaisaJobStatus: (state, action) => {
      state.epAutoJobStatus = action.payload;
    },
    resetUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setChangeStatus: (state, action) => {
      const { status, id } = action.payload;
      state.data = state.data.map((r) => (r._id === id ? { ...r, status } : r));
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
});

export const loanRequestsReducer = loanRequestsSlice.reducer;

export const loanRequestsActions = {
  ...loanRequestsSlice.actions,
  getLoanRequests,
  searchLoanRequests,
  getFileRecords,
  changeStatus,
  fileUpload,
  fileParsed,
  refreshLoanRequest,
  deleteFile,
  processAutoJobs,
  processEasypaisaAutoJobs,
  getJobStatus,
  getEasypaisaJobStatus
};
