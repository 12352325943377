import * as React from "react";
import { countries } from "&config/countries";
import ReactFlagsSelect from "react-flags-select";

type CountryType = {
  code: string;
  name: string;
};

export default function CountriesWithFlags({ value, onSelect }) {
  const countryCodes: string[] = [];
  const customLabes = {};

  countries.forEach((country: CountryType) => {
    countryCodes.push(country.code);
    customLabes[country.code] = { primary: country.name };
  });
  return (
    <ReactFlagsSelect
      selected={value}
      onSelect={onSelect}
      countries={countryCodes}
      customLabels={customLabes}
      searchable
      selectButtonClassName="custom-flag-select"
      className="custom-flags-bg"
    />
  );
}
