import * as React from "react";
import Calendar from "../images/calendar.png";
export default function CalendarIcon() {
  return (
    <img
      src={Calendar}
      alt="calendar-icon"
      style={{ width: "16px", height: "16px", marginRight: "0.5rem" }}
    />
  );
}
