import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import qs from 'query-string';
import Box from '@mui/material/Box';
import { RootState } from "&store/store";
import { creditCardRedirectActions } from "./creditCardRedirect.slice";
import {servicesActions} from '&features/services/services.slice';
import Loader from "&styled/loader/loader";

type ReduxProps = ConnectedProps<typeof connector>;

const CreditCardRedirectComponent = (props: ReduxProps) => {
  const {serviceId,msisdn, queryParams, getService, checkout} = props;
  const parsed = qs.parse(queryParams);
  parsed.serviceId = serviceId;
  parsed.msisdn = msisdn;
  parsed.paymentMethod="CC";
  parsed.orderId=parsed.orderRefNumber;

  useEffect(() => {
(async()=>{
  // get service by sid
  const {payload} = await getService(serviceId)
  // update transaction
  await checkout(parsed)

  // get the redirect url
  const clientUrl = payload.portalUrl;
  // modify the response and redirect to client
  let redirectStr = "";
  if(parsed.status === "0000"){
    redirectStr = `?status=SUCCESS&transactionId=${parsed.transactionRefNumber}&amount=${parsed.transactionAmount}&orderId=${parsed.orderRefNumber}`
  }else{
    redirectStr = `?status=FAILED&orderId=${parsed.orderRefNumber}`
  }
 window.location.href = clientUrl + redirectStr;
})()
  }, [queryParams, serviceId, getService,parsed, checkout]);

  return (
    <Box sx={{width:"100vw", height: "100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Loader/>;
    </Box>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
  msisdn: ownProps.match.params.msisdn,
  queryParams: ownProps.location.search
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getServiceBySid,
  checkout: creditCardRedirectActions.checkout
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const CreditCardRedirectComponentRedux = connector(CreditCardRedirectComponent);

export { CreditCardRedirectComponentRedux as CreditCardRedirectComponent };
