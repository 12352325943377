export const CheckMimeType = (files) => {
  let err = "";
  const types = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];

  if (types.every((type) => files.type !== type)) {
    err += files.type + " is not a supported format\n";
  }

  if (err !== "") {
    return false;
  } else {
    return true;
  }
};
