import React from "react";
import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { startCase } from "lodash";
import { LinkedButtonProps, SubmitButtonProps } from "./button.interface";
import { colorPalette } from "&config/colors";

const useStyles = makeStyles<Theme, any>((theme: Theme) => ({
  root: (props: any) => ({
    "& .MuiButton-startIcon": {
      margin: props.language === "en" ? "0px 8px 0px -4px" : "0px 8px 0px -4px",
    },
  }),
}));

const LinkButton = (props: LinkedButtonProps) => {
  const { title, handlePress, Icon, styles, language, color } = props;
  const classes = useStyles({ language });

  return (
    <Button
      variant="text"
      onClick={() => handlePress()}
      className={classes.root}
      style={{
        textDecoration: "underline",
        color: color ? color : "#ffffff",
        textTransform: "none",
        ...styles,
      }}
      startIcon={Icon && <Icon />}
    >
      {startCase(title)}
    </Button>
  );
};

const SubmitButton = (props: SubmitButtonProps) => {
  const {
    title,
    handlePress,
    disabled = false,
    fullWidth = true,
    styles,
  } = props;

  return (
    <Button
      variant="contained"
      onClick={() => handlePress()}
      fullWidth={fullWidth}
      disabled={disabled}
      style={{
        ...styles,
        color: "#ffffff",
        backgroundColor: colorPalette.primary,
        borderRadius: "4px",
        opacity: disabled ? 0.5 : 1,
        textTransform: "none",
      }}
    >
      {title}
    </Button>
  );
};

const OutlinedButton = (props: SubmitButtonProps) => {
  const {
    title,
    handlePress,
    disabled = false,
    fullWidth = true,
    styles,
  } = props;

  return (
    <Button
      variant="outlined"
      onClick={() => handlePress()}
      fullWidth={fullWidth}
      disabled={disabled}
      style={{
        ...styles,
        color: "#6631F7",
        borderRadius: "5px",
        opacity: disabled ? 0.5 : 1,
        border: "solid 1px #6631F7",
        textTransform: "none",
      }}
    >
      {startCase(title)}
    </Button>
  );
};

export { LinkButton, SubmitButton, OutlinedButton };
