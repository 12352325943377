import * as React from "react";

export default function CreateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.5"
      height="18.5"
      viewBox="0 0 18.5 18.5"
    >
      <g
        id="Iconly_Light_Plus"
        data-name="Iconly/Light/Plus"
        transform="translate(0.75 0.75)"
      >
        <g id="Plus" transform="translate(0)">
          <path
            id="Line_185"
            d="M.476,0V6.227"
            transform="translate(8.024 5.378)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Line_186"
            d="M6.233.476H0"
            transform="translate(5.383 8.016)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path"
            d="M12.483,0H4.517C1.74,0,0,1.965,0,4.747v7.505C0,15.035,1.732,17,4.517,17h7.966C15.268,17,17,15.035,17,12.253V4.747C17,1.965,15.268,0,12.483,0Z"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
