import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Blacklists } from "./blacklists.type";


const initialState: Blacklists = {
  data: []
};

const getBlacklists = createAsyncThunk(
  "blacklists/getBlacklistsCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/black-lists/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from blacklists :", response.data);
      dispatch(blacklistsActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const getBlacklist = createAsyncThunk(
  "blacklists/getBlacklistCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/black-lists/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      console.log("Success response from blacklist :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addBlacklist = createAsyncThunk(
  "blacklists/addBlacklistCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/black-lists/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from adding blacklist :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editBlacklist = createAsyncThunk(
  "blacklists/editBlacklistCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/black-lists/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
      console.log("Success response from editing blacklist :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deleteBlacklist = createAsyncThunk(
  "blacklists/deleteBlacklistCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/black-lists/delete/${body}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(blacklistsActions.setDeleteBlacklist(body));
      console.log("Success response from deleting blacklist :", response.data);

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const blacklistsSlice = createSlice({

  name: "blacklists",

  initialState: initialState,

  reducers: {
     setBlacklists: (state, action) => {
      return { ...state, ...action.payload };
    },
    setDeleteBlacklist: (state, action) => {
      state.data = state.data.filter((val) => val._id !== action.payload);
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
});

export const blacklistsReducer = blacklistsSlice.reducer;


export const blacklistsActions = { ...blacklistsSlice.actions, getBlacklists,getBlacklist, addBlacklist,editBlacklist, deleteBlacklist};
