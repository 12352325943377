import * as React from "react";

export default function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.265"
      height="16.495"
      viewBox="0 0 14.265 16.495"
    >
      <g
        id="Iconly_Light_Delete"
        data-name="Iconly/Light/Delete"
        transform="translate(0.75 0.75)"
      >
        <g id="Delete">
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M10.682,0s-.409,5.459-.646,7.759A1.74,1.74,0,0,1,8.261,9.521c-1.964.038-3.93.041-5.893,0A1.725,1.725,0,0,1,.645,7.764C.406,5.445,0,0,0,0"
            transform="translate(1.042 5.445)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M12.765.5H0"
            transform="translate(0 2.328)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M7.846,2.828A1.267,1.267,0,0,1,6.63,1.755L6.447.769A.978.978,0,0,0,5.516,0H2.33A.978.978,0,0,0,1.4.769l-.183.986A1.267,1.267,0,0,1,0,2.828"
            transform="translate(2.46)"
            fill="none"
            stroke="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
