import * as React from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ReactComponent as ViewDetailIcon } from "&assets/icons/view-detail.svg";

export default function CardView({ data }) {
  const history = useHistory();
  let pkFormatter = new Intl.NumberFormat("en-PK");

  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid key={index} item lg={3}>
          <Card sx={{ height: "265px", width: "100%" }} elevation={0}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {/*Header*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  {item.merchantName}
                </Box>
                <Box>
                  <ViewDetailIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/loan-account-detail/${item.userTypeId}`)
                    }
                  />
                </Box>
              </Box>
              {/*Transaction details*/}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ fontSize: "0.8rem" }}>Total Transactions</Box>
                  <Box
                    sx={{
                      backgroundColor: "rgba(253, 117, 67, 0.17)",
                      color: "#fd7543",
                      padding: "0.1rem 0.7rem",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    {item.totalTransactions || 0}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ fontSize: "0.8rem" }}>Transaction Amount</Box>
                  <Box
                    sx={{
                      backgroundColor: "#F6F2FF",
                      color: "#6631F7",
                      padding: "0.1rem 0.7rem",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    {pkFormatter.format(item.totalTransactionsAmount || "0")}
                  </Box>
                </Box>
              </Box>
              {/*Balance (Footer)*/}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Available Balance
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "baseline",
                    color: "#6631F7",
                  }}
                >
                  <Box sx={{ fontSize: "0.7rem" }}>{item.currency}</Box>
                  <Box sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    {pkFormatter.format(item.availableBalance)}
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
