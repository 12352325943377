import * as React from "react";

export default function ServicesIcon() {
  return (
    <svg
      id="Iconly_Light-Outline_Setting"
      data-name="Iconly/Light-Outline/Setting"
      xmlns="http://www.w3.org/2000/svg"
      width="16.57"
      height="17.804"
      viewBox="0 0 16.57 17.804"
    >
      <g id="Setting" transform="translate(0)">
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M7.745,17.8a2.291,2.291,0,0,1-2.289-2.288.926.926,0,0,0-.13-.457,1,1,0,0,0-.6-.471.962.962,0,0,0-.25-.032,1,1,0,0,0-.5.133,2.345,2.345,0,0,1-1.138.3,2.278,2.278,0,0,1-.618-.085A2.306,2.306,0,0,1,.842,13.809L.3,12.883a2.284,2.284,0,0,1,.834-3.115,1,1,0,0,0,0-1.731A2.294,2.294,0,0,1,.3,4.915l.582-.957A2.286,2.286,0,0,1,4,3.129a.945.945,0,0,0,.475.135,1,1,0,0,0,.991-.984A2.279,2.279,0,0,1,7.745,0H8.822A2.285,2.285,0,0,1,11.11,2.353a.911.911,0,0,0,.13.438.994.994,0,0,0,.608.462,1.063,1.063,0,0,0,.251.031.981.981,0,0,0,.5-.137,2.3,2.3,0,0,1,3.127.84l.535.927a.706.706,0,0,1,.036.073,2.3,2.3,0,0,1-.868,3.046.974.974,0,0,0-.364.362,1.014,1.014,0,0,0-.1.759.982.982,0,0,0,.464.6,2.281,2.281,0,0,1,.834,3.125l-.571.95a2.289,2.289,0,0,1-3.117.824,1.016,1.016,0,0,0-.479-.134h0a1,1,0,0,0-.689.291.98.98,0,0,0-.29.705A2.3,2.3,0,0,1,8.822,17.8Zm-2.69-4.461A2.3,2.3,0,0,1,6.44,14.409a2.208,2.208,0,0,1,.305,1.1,1,1,0,0,0,1,1.006H8.822a1,1,0,0,0,1-.994,2.282,2.282,0,0,1,2.234-2.285h.058a2.356,2.356,0,0,1,1.1.3,1.006,1.006,0,0,0,1.366-.362l.571-.951a.993.993,0,0,0-.367-1.35,2.274,2.274,0,0,1-1.067-1.39,2.3,2.3,0,0,1,.233-1.735,2.267,2.267,0,0,1,.834-.832,1,1,0,0,0,.359-1.356.416.416,0,0,1-.03-.059l-.5-.873a1,1,0,0,0-1.363-.369,2.247,2.247,0,0,1-1.143.314,2.3,2.3,0,0,1-1.974-1.13,2.2,2.2,0,0,1-.309-1.105,1.028,1.028,0,0,0-.282-.751,1,1,0,0,0-.718-.3H7.745a.992.992,0,0,0-.7.291.981.981,0,0,0-.288.7A2.3,2.3,0,0,1,4.465,4.553a2.227,2.227,0,0,1-1.117-.312.974.974,0,0,0-.483-.127,1,1,0,0,0-.871.5l-.582.957a.994.994,0,0,0,.371,1.349,2.288,2.288,0,0,1,0,3.963.993.993,0,0,0-.362,1.353l.542.935a1.006,1.006,0,0,0,1.37.4,2.246,2.246,0,0,1,1.137-.3A2.287,2.287,0,0,1,5.055,13.343ZM5.378,8.9a2.909,2.909,0,1,1,2.909,2.908A2.913,2.913,0,0,1,5.378,8.9Zm1.289,0a1.621,1.621,0,1,0,1.62-1.621A1.622,1.622,0,0,0,6.667,8.9Z"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
}
